import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'

import webDev from '@/images/webdev.jpg'

function ReadyToHelp() {
  return (
    <section
      id="contact-us"
      aria-label="Pricing"
      className="bg-slate-900 py-20 sm:py-32"
    >
      
        <div className='flex flex-wrap flex-col w-full lg:w-3/4 lg:mx-auto lg:flex-row'>
      <div className="w-3/4 lg:w-1/2 xl:w-1/3 relative lg:ml-auto overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10 self-center lg:self-end">
      <Image
                className="w-full"
                src={webDev}
                alt=""
                sizes="52.75rem"
              />
            </div>
      <div className=" w-full lg:w-1/2 relative mr-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        
        <div className=" lg:pl-10">
          <h2 className="text-lg font-semibold text-gray-300">Award winning support</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">We’re here to help</p>
          <p className="mt-3 text-lg text-gray-300">
            Whatever you needs our designers and developers will make your ideas a reality.
          </p>
          <div className="mt-8 min-w-fit">
            <div className="inline-flex rounded-md shadow min-w-fit">
              <Link
                href="/contact"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-gray-900 hover:bg-gray-50"
              >
                <span className=' flex max-w-fit items-center'>
                Contact Us
                <ArrowTopRightOnSquareIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      </div>

    </section>
  )
}

export default ReadyToHelp