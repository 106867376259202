import Head from 'next/head'

import { CallToAction } from '@/components/CallToAction'
import { Footer } from '@/components/Footer'
import { Header } from '@/components/Header'
import { Hero } from '@/components/Hero'
import { SecondaryFeatures } from '@/components/SecondaryFeatures'

import ReadyToHelp from '@/components/ReadyToHelp'

export default function Home() {
  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="keywords" content="web design development application" />
        <title>
          EMP Development - Build a website that tells your story in an impactful
          way
        </title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="description"
          content="Web design and web application development agency"
        />
      </Head>
      <Header />
      <main>
        <Hero />
        <CallToAction />
        <SecondaryFeatures />
        <ReadyToHelp />
      </main>
      <Footer />
    </>
  )
}
